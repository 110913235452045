<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="video-loop wrapper">
    <div class="video-loop-inner">
      <video
        v-if="isMobile && (content.videoMobile || content.videoMobileFallback)"
        width="100%"
        height="100%"
        autoplay
        muted
        loop
      >
        <source :src="content.videoMobile" type="video/webm" />
        <source :src="content.videoMobileFallback" type="video/mp4" />
      </video>
      <video v-else width="100%" height="100%" autoplay muted loop>
        <source :src="content.videoDesktop" type="video/webm" />
        <source :src="content.videoDesktopFallback" type="video/mp4" />
      </video>
    </div>
  </div>
</template>

<script lang="ts">
interface Loop {
  videoDesktop: string
  videoDesktopFallback: string
  videoMobile: string
  videoMobileFallback: string
}
</script>

<script setup lang="ts">
import { useResponsive } from '@/utils/aware'

import type { PropType } from 'vue'

const isMobile = useResponsive('smaller', 'm')

defineProps({
  content: {
    type: Object as PropType<Loop>,
    required: true,
  },
})
</script>

<style lang="scss" scoped>
.video-loop {
  // prettier-ignore
  @include fluid(margin-top, (xxs: 2.4rem, xxl: 6.4rem));

  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 6rem, xxl: 12rem));

  video {
    object-fit: cover;
  }
}

.video-loop-inner {
  @extend %br-default;

  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 1;

  @include mq(m) {
    aspect-ratio: 160/68;
  }
}
</style>
